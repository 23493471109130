.footer {
    background-color: #080E57;
    color: white;
    /* padding: 40px 10%; */
    position: relative;
    display: flex;
    flex-direction: column;
    min-height: 250px;
    height: 460px;
  }
  
  .footer-gradient {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 62px;
    background: linear-gradient(180deg, #DDDFFF 0%, #080E57 100%);
  }
  
  .footer-content {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-grow: 1;
    padding-top: 50px;
    margin: 0 165px 0 165px;
  }
  
  .footer-left, .footer-right {
    flex: 1;
  }
  .footer-left{
    margin-top: 37px;
  }
  .footer-right{
    margin-top: 107px;
  }
  
  .footer-center {
    /* flex: 1; */
    margin-bottom: 18px;
    /* align-items: center; */
    text-align: center;
  }
  
  .footer-logo {
    display: flex;
    align-items: center;
    font-size: 24px;
    font-weight: 600;
    margin: 37px 0 33px 0;
    font-family: 'Inter', sans-serif;
    /* figma css */
    color: #FFF;
    text-align: center;
    font-family: 'Inter', sans-serif;
    font-size: 26px;
    font-style: normal;
    font-weight: 600;
    line-height: 37px; /* 142.308% */
  }
  
  .footer-logo img {
    width: 33px;
    height: 33px;
    margin-right: 10px;
  }
  
  .footer-links-title {
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 18px;

    color: #FFF;
    font-family: 'Inter', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  
  .footer-links {
    list-style: none;
    padding: 0;
    /* margin: 10px 0; */
  }
  
  .footer-links li {
    margin-bottom: 11px;
    cursor: pointer;
    color: #9095C7;
    font-size: 18px;
    font-weight: 600;
    font-family: 'Inter', sans-serif;
  }
  
  .footer-links li:hover {
    color: #FFF;
  }
  
  .footer-right {
    text-align: right;
  }
  .footer-right_new {
    text-align: left;
    margin-top: 48px;
  }
  
  .footer-bottom {
    display: flex;
    justify-content: space-between;
    font-size: 14px;
    position: absolute;
    bottom: 53px;
    /* left: 10%;
    right: 10%; */
    margin-left: 165px;
  }
  
  .footer-bottom a {
    text-decoration: none;
    color: #FFF;
    text-align: center;
    font-family: 'Inter', sans-serif;
    font-size: 18px;
    font-style: normal;
    /* font-weight: 600; */
    line-height: 37px; /* 205.556% */
  }
  
  .footer-bottom p {
    text-decoration: none;
    color: #FFF;
    text-align: center;
    font-family: 'Inter', sans-serif;
    font-size: 18px;
    font-style: normal;
    /* font-weight: 600; */
    line-height: 37px; /* 205.556% */
    padding: 0px;
  }
  
  .footer-bottom a:hover {
    text-decoration: underline;
  }
  
  .footer-image {
    /* max-width: 100%;
    height: auto; */
    margin-top: 53px;
    width: 325px;
    height: 316px;
  }

  .footer-connect{
    color: #969CC7;
    text-align: right;
    font-family: 'Inter', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal; 
  }
  .footer-email{
    color: #FFF;
    text-align: right;
    font-family: 'Inter', sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    padding: 0;
    margin-top: 13px;
  }
  .footer-connect_new{
    color: #969CC7;
    text-align: left;
    font-family: 'Inter', sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal; 
  }
  .footer-email_new{
    color: #FFF;
    text-align: left;
    font-family: 'Inter', sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    padding: 0;
    margin-top: 13px;
  }
.footer-links-title{
  font-size: 20px;
}
.footer-right{
    display: none !important
}
  
  /* Mobile view */
  @media (max-width: 768px) {
    .footer {
      padding: 40px 5%; /* Reduce padding for smaller screens */
      height: auto; /* Auto height to fit content */
      min-height: 400px;
    }
  
    .footer-content {
      flex-direction: column; /* Stack elements vertically */
      align-items: flex-start; /* Align content to left */
      text-align: left;
      padding-top: 20px;
      margin: 0px;
    }
  
    .footer-left,
    .footer-center,
    .footer-right {
      flex: unset; /* Remove flex distribution */
      width: 100%; /* Full width */
      text-align: left; /* Align text */
      margin-bottom: 20px; /* Add spacing between sections */
    }
  
    .footer-logo {
      font-size: 20px; /* Adjust logo size */
      margin: 35px 0 47px 0;
    }
  
    .footer-links-title {
      font-size: 16px; /* Reduce title size */
      margin-bottom: 23px;
    }
  
    .footer-links li {
        font-size: 14px;
        margin-bottom: 20px;
        font-family: 'Inter', sans-serif;
    }
  
    .footer-center{
        display: none;
    }
    .footer-right{
        margin: 37px 0 81px;
        display: block !important
    }
    .footer-right_new{
        display: none;
    }
    .footer-connect,
    .footer-email {
      text-align: left; /* Align email section */
      font-size: 18px; /* Adjust font size */
    }
  
    .footer-bottom {
      flex-direction: column; /* Stack bottom links */
      text-align: left;
      position: relative; /* Avoid absolute positioning */
      bottom: unset;
      left: unset;
      right: unset;
      margin-left: 0;
    }
  
    .footer-bottom p,
    .footer-bottom a {
      font-size: 16px;
      margin-bottom: 10px;
      text-align: left;
      padding: 0;
      margin: 0;
    }
  }
  