
.global-language {
    text-align: center;
    background: 
      linear-gradient(to bottom, #FFFFFF 0px, #DDDFFF 792px),
      #DDDFFF;
      /* background: linear-gradient(to bottom, #DDDFFF 0%, #DDDFFF 35%, #FFFFFF 50%, #DDDFFF 65%, #DDDFFF 100%); */
      height: 957px;
    
    /* margin-bottom: 100px; */
  }

  html {
    scroll-padding-top: 65px;
  }
  .global-language h2 {
    /* color: #080e57;
    font-size: 24px;
    font-weight: bold; */

    /* figma css */
    color: #080E57;
    text-align: center;
    font-family: 'Inter', sans-serif;
    font-size: 50px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-bottom: -0px;
    /* margin-top: 123px; */
  }
  
  .language-info {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    /* margin: 20px 0; */
  }
  
  .number {
    /* font-size: 60px;
    font-weight: bold;
    color: #080e57; */

    /* figma css */
    color: #080E57;
    text-shadow: 0px 0px 4px rgba(255, 255, 255, 0.50);
    font-family: 'Inter', sans-serif;
    font-size: 150px;
    font-style: normal;
    font-weight: 500;
    line-height: 120%; /* 180px */
  }
  
  .text {
    /* font-size: 20px;
    color: #080e57; */

    /* figma css */
    color: #080E57;
    text-shadow: 0px 0px 4px rgba(255, 255, 255, 0.50);
    font-family: 'Inter', sans-serif;
    font-size: 40px;
    font-style: normal;
    font-weight: 500;
    line-height: 105%; /* 42px */
    text-align: left;
    margin-left: 32px;
  }
  
  .world-map {
    width: 85%;
    /* height: inherit; */
    /* height: 711px;
    margin-top: 20px; */
  }
  
  /* Mobile View CSS */
  @media (max-width: 768px) {
    .global-language{
      height: 440px;
      /* height: 552px; */
    }
    .global-language h2{
      /* margin-top: 60px; */
      font-size: 34px;
      
    }
    .number{
      width: 109px;
      height: 89px;
      flex-shrink: 0;
      font-size: 75px;
    }
    .text{
      text-align: left;
      margin-left: 0px;
      font-size: 35px;
      line-height: 105%;
      /* margin-top: 48px; */
    }
    .language-info{
      align-items: center;
      gap: 0px;
    }
    
    .global-language img{
      width: 361px;
      height: 201px;
      flex-shrink: 0;
      margin-top: 38px;
    }
  }