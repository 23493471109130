.inclusion-section {
    position: relative;
    padding: 60px 20px;
    text-align: center;
    overflow: hidden;
    width: 1442px;
    height: 855px;
    flex-shrink: 0;
    background:
    linear-gradient(to bottom, #DDDFFF 0px, #FFFFFF 62px), 
    #ffffff;
  }
  
  /* Background SVG */
  .svg-background {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%); /* Centers the SVG horizontally */
    width: 100%; /* Ensures actual SVG width */
    height: 625px; /* Ensures actual SVG height */
    overflow: hidden;
}

.background-svg {
    width: 100%;
    height: auto;
    display: block;
}

  
  /* Content Styles */
  .inclusion-container {
    max-width: 1000px;
    margin: auto;
    position: relative;
    /* z-index: 1; */
  }
  .inclusion-container h2{
    color: #080E57;
    text-align: center;
    font-family:  'Inter', sans-serif;
    font-size: 50px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  
  .inclusion-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
  }
  
  .inclusion-left {
    flex: 1;
    text-align: left;
    font-size: 24px;
    font-weight: bold;
    color: #080e57;
  }
  .inclusion-left h3{
    color: #080E57;
    text-align: center;
    font-family:  'Inter', sans-serif;
    font-size: 48px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-top: -108px;
    margin-left: -40px;
  }
  
  .inclusion-right {
    flex: 1;
    text-align: left;
  }
  
  .inclusion-right ul {
    list-style: none;
    padding: 0;
  }
  
  .inclusion-right li {
    display: flex;
    align-items: center;
    margin-bottom: 34px;

    color: #080E57;
    font-family: 'Inter', sans-serif;
    font-size: 26px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  
  .inclusion-right li img {
    width: 73px;
    height: 73px;
    margin-right: 10px;
  }
  
  .different-order {
    font-weight: bold;
  }
  
  .different-order p {
    font-size: 14px;
    font-weight: normal;
    margin: 5px 0 0 35px;
  }

  .inclusion-right li:nth-child(6) p{
    color: red;
    margin-left: 72px;
    text-align: left;
    margin-top: -57px;
    padding: 13px;
    color: #545454;
    font-family: 'Inter', sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}


.inclusion-section {
    width: 100%; /* Ensure full width */
    max-width: 100vw; /* Prevent overflow */
    overflow: hidden;
}

/* Background SVG */
.svg-background {
    width: 100vw; /* Ensures full viewport width */
    left: 0; /* Align to the left */
    transform: none; /* Remove translateX to prevent centering issues */
}
.mobile-view-tag{
    display: none;
}

/* Mobile css */
@media screen and (max-width: 768px) {
    .desktop-view{
        display: none;
    }
    .mobile-view-tag{
        display: block;
    }
    .inclusion-section {
      /* background: none; */
      background:
      linear-gradient(to bottom, #DDDFFF -50px, #FFFFFF 62px), 
      #ffffff;
    }
   
.inclusion-content {
    display: flex;
    /* justify-content: space-between; */
    align-items: flex-start;
    /* flex-wrap: wrap; */
    flex-direction: column;
}
.inclusion-container h2{
  font-size: 34px;
  margin-bottom: 17px;
}
.inclusion-left h3{
  font-size: 24px;
  margin: 0 0 36px 0;
}
.inclusion-right li{
  margin-bottom: 30px;
  color: #080E57;
  font-size: 20px;
}

.inclusion-right ul img{
  width: 56px;
  height: 56px;
}
.inclusion-right li:nth-child(6){
  margin-bottom: 0px;
}
.inclusion-right li:nth-child(6) p{
  font-size: 14px;
  margin-left: 52px;
  margin-top: -30px;
  padding-top: 7px;
}

}