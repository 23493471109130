.section7a-container {
    position: relative; /* Ensures absolute child positions relative to this */
    text-align: center;
    padding: 50px 10%;
    background: #FFFFFF; /* Optional: Helps blend the gradient */
    overflow: hidden;
    /* background: #DDDFFF; */
  }
  html {
    scroll-padding-top: 80px; 
  }
  .top-gradient {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 160px;
    background: 
        linear-gradient(to bottom, #DDDFFF 0%, #FFFFFF 100%)
        #FFFFFF;
    z-index: 1; 
  }
  
  .section7a-title {
    color: #080e57;
    font-family: 'Inter', sans-serif;
    font-size: 50px;
    font-weight: 700;
    position: relative; /* Ensures text appears above the gradient */
    z-index: 2;
    margin: 90px 0 -0px 0;
  }
  
  .section7a-subtitle {
    color: #080e57;
    font-family: 'Inter', sans-serif;
    font-size: 28px;
    font-weight: 600;
    margin-top: 10px;
    position: relative;
    z-index: 2;
  }
  
  .section7a-image {
    display: flex;
    justify-content: center;
    margin-top: 30px;
    position: relative;
    z-index: 2;
  }
  
  .section7a-image img {
    max-width: 100%;
    height: auto;
  }

  .inclusion-grid{
    display: none;
  }
  
  /* Mobile View CSS */
  @media (max-width:768px) {
    .section7a-title{
      margin-bottom: 17px;
      font-size: 34px;
    }
    .section7a-subtitle{
      margin-bottom: 32px;
      font-size: 24px;
    }
    .inclusion-grid{
      display: block;
      display: grid;
      align-items: center;
      grid-template-columns: repeat(2, 1fr); /* Two cards per row */
      gap: 33.1px;
      justify-content: center;
    }
    .section7a-image{
      display: none;
    }
  }