.louie-customers {
    text-align: center;
    background: 
        linear-gradient(to bottom, #FFFFFF 0px, #DDDFFF 61px), 
       #DDDFFF;
    padding: 50px 0;
    overflow: hidden;
    height: 782px;
}
  
.louie-customers h2 {
    color: #080E57;
    text-align: center;
    font-family: Inter;
    font-size: 50px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}
  
.carousel {
    width: 100%;
    overflow: hidden;
    position: relative;
}
  
.carousel-track {
    display: flex;
    gap: 20px;
    width: max-content;
    animation: infiniteScroll 15s linear infinite;
}
  
  .carousel:hover .carousel-track {
    animation-play-state: paused;
  }
  
  @keyframes infiniteScroll {
    from {
      transform: translateX(0);
    }
    to {
      transform: translateX(-50%);
    }
  }
  
  .testimonial-card {
    width: 250px;
    background: #fff;
    padding: 20px;
    text-align: left;
    position: relative;
    flex-shrink: 0;

    width: 298px;
    height: 375px;
    flex-shrink: 0;
    border-radius: 20px;
    background: #FFF;
    box-shadow: 0px 0px 15px 0px rgba(8, 14, 87, 0.32);
    margin: 0 32px 0 32px;
  }
  
  .testimonial-content {
    /* width: 298px; */
    margin-top: 100px;
    height: 198px;
    flex-shrink: 0;
    padding: 10px;
    background: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="298" height="198" viewBox="0 0 298 198" fill="none"><path d="M150.82 116.268C190.283 42.8988 247.322 8.18553 270.909 0H298V198H0V187.933C33.8299 194.615 111.356 189.637 150.82 116.268Z" fill="url(%23paint0_linear_566_530)"/><defs><linearGradient id="paint0_linear_566_530" x1="149" y1="0" x2="149" y2="217" gradientUnits="userSpaceOnUse"><stop stop-color="white"/><stop offset="1" stop-color="%23D1D5FF"/></linearGradient></defs></svg>') no-repeat bottom center;
    background-size: cover
  }
  
  .testimonial-card h3 {
    font-size: 16px;
    font-weight: bold;
  }
  
  .testimonial-card h4 {
    font-size: 14px;
    font-weight: normal;
    color: gray;
  }
  
  .testimonial-card p {
    font-size: 14px;
    color: #19232b;
  }
  
  .quote-icon {
    position: absolute;
    top: 15px;
    right: 15px;
    font-size: 40px;
    color: rgba(0, 0, 0, 0.1);
  }
  