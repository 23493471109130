.dflex{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}
.old-footer {
    background-color: #f8fbff;
    padding: 15px 0;
    font-size: 14px;
    color: #333;
    
}
  
.old-footer a {
    color: #007bff;
    text-decoration: none;
    font-weight: 500;
}

.old-footer a:hover {
    text-decoration: underline;
}
.footer-row{
    margin: 0 140px 0 140px;
}
  