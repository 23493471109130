.container {
  background: 
      linear-gradient(to bottom, #FFFFFF 0px, #DDDFFF 61px), 
      #DDDFFF;
  padding: 80px 10%;
  height: 603px;
  display: flex;
  flex-direction: column;
  align-items: center; /* Centers everything inside */

}

.container h2 {
  color: #080E57;
  font-family: 'Inter', sans-serif;
  font-size: 50px;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 40px;
  text-align: center; /* Centers text */

}

.my-creater {
  display: flex;
  align-items: center;
  justify-content: center; /* Centers the content */
  gap: 40px;
}

/* Image styling with shadow effect */
.image {
  position: relative;
  width: 180px;
  height: 180px;
  border-radius: 20px;
}

.image::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background: #C5C8FF;
  border-radius: 20px;
  bottom: 30px;
  right: 32px;
  z-index: 0;
}

.image img {
  position: relative;
  width: 100%;
  height: 100%;
  border-radius: 20px;
  z-index: 1;
}

.my-creator-text {
  max-width: 600px;
}

.my-creator-text p {
  text-align: left;
  color: #19232B;
  font-family: 'Inter', sans-serif;
  font-size: 22px;
  font-weight: 400;
  line-height: 127%;
}

@media (max-width: 768px) {
  .container h2{
    font-size: 34px;
    margin-bottom: 48px;
  }
  .my-creater{
    flex-direction: column;
  }
  .my-creator-text p{
    font-size: 14px;
  }
}