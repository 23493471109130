.section3 {
    text-align: center;
    padding: 80px 10%;
    background:
      /* linear-gradient(to bottom, #DDDFFF 0px, #FFFFFF 132px),  */
      #ffffff;
    margin-top: 100px;
  }
  
  .section3-title {
    /* font-size: 28px;
    font-weight: bold;
    color: #0b1446; */

    /* figma css */
    color: #080E57;
    text-align: center;
    text-shadow: 0px 0px 14px rgba(255, 255, 255, 0.50);
    font-family: 'Inter', sans-serif;
    font-size: 50px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  .section3-title span{
    font-family: 'Inter', sans-serif;
  }
  .section3-content {
    display: flex;
    justify-content: center;
    /* justify-content: space-between; */
    align-items: flex-start;
    gap: 125px;
    margin-top: 102px;
    /* flex-wrap: wrap; */
  }
  
  .assistant {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
  }
  .assistant p{
    font-family: 'Inter', sans-serif;
  }

  .assistant-label {
    /* writing-mode: sideways-lr; */
    writing-mode: vertical-rl;
    transform: rotate(180deg);
    text-orientation: mixed;
    position: absolute;
    left: -50px;
    top: 10%;
    bottom:10%;
    color: rgba(8, 14, 87, 0.15);
    font-family: 'Inter', sans-serif;
    font-size: 35px;
    font-weight: 800;
    text-transform: uppercase;
  }
  
  /* .louie {
    color: #0b1446;
    text-align: center;
  }
  
  .google {
    color: #4285f4;
  }
  
  .siri {
    color: #9c27b0;
  } */
  
  .phone {
    /* width: 180px;
    height: 360px; */
    background-color: #0b1446;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 21px;
    flex-direction: column;
    border-radius: 20px;
    /* box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2); */

    position: relative;
    z-index: 1; 
    padding: 20px;
    overflow: hidden;
    
    /* figma css */
    background: #060E55;
    width: 243px;
    height: 520px;
    flex-shrink: 0;
    border-radius: 30px;
    border: 9px solid #DAE3EA;
  }
  
  .phone p {
    color: white;
    font-size: 14px;
    text-align: center;
    max-width: 80%;
    font-family: 'Inter', sans-serif;

  }
  
  /* SVG Styling */
  .louie-svg {
    position: absolute;
    top: -40px;
    left: -30px;
    width: 50px;
    height: 50px;
    z-index: 0;
  }
  /* Louie Voice SVG */
/* .louie-svg {
    position: absolute;
    top: -35px;
    left: -25px;
    width: 80px;
    height: 80px;
    z-index: -1; 
    overflow: hidden;
} */
  

/* Wrapper for phone to control overflow */
.phone-container {
    position: relative;
    display: inline-block;
  }
  
.phone-container span{
  font-family: 'Inter', sans-serif;
}
  /* Louie Voice Circle SVG */
  .louie-svg {
    position: absolute;
    top: -35px; /* Adjust as needed */
    left: -35px; /* Adjust to match visibility */
    width: 100px;
    height: 100px;
    z-index: 0; /* Ensures it's behind the phone */
  }

  .google-img {
    position: absolute;
    top: -45px; /* Adjust as needed */
    left: -55px; /* Adjust to match visibility */
    width: 100px;
    height: 100px;
    z-index: 0; /* Ensures it's behind the phone */
  }
  .siri-img {
    position: absolute;
    top: -45px; /* Adjust as needed */
    left: -55px; /* Adjust to match visibility */
    width: 100px;
    height: 100px;
    z-index: 0; /* Ensures it's behind the phone */
  }

  .phone p {
    color: white;
    font-size: 14px;
    text-align: center;
    max-width: 80%;
    font-family: 'Inter', sans-serif;
    z-index: 2;
    position: relative;
  }
  
  .phone video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 20px;
    opacity: 1;
    transition: opacity 0.3s ease-in-out;
  }
  
  .phone:hover video {
    opacity: 1;
  }
  video::-webkit-media-controls-fullscreen-button {
    display: none;
  }
  
  video::-webkit-media-controls-download-button {
    display: none;
  }

  /* video::-webkit-media-controls-enclosure {
    display: none;
  } */
  

  /* mobile view */
  @media screen and (max-width: 768px) {
    .section3 {
        padding: 0px 10%;
    }

    .section3-title {
        font-size: 34px;
    }

    .section3-content {
        flex-direction: column;
        align-items: center;
        gap: 50px;
        margin-top: 50px;
    }

    /* .assistant-label {
        position: relative;
        text-align: center;
        left: 0;
        top: 0;
        margin-bottom: 10px;
        font-size: 20px;
        
    
        text-orientation: mixed;
        position: absolute;
        left: -50px;
        top: 0%;
        bottom:0%;
        color: rgba(8, 14, 87, 0.15);
        font-family: 'Inter', sans-serif;
        font-size: 35px;
        font-weight: 800;
        text-transform: uppercase;
    } */

    .assistant-label {
      left: -30px;
      font-size: 20px; 
    }

    .phone {
        /* width: 200px;
        height: 400px;
        padding: 15px; */
        width: 224px;
        height: 506px;
        flex-shrink: 0;
    }

    .phone p {
        font-size: 12px;
    }

    .louie-svg,
    .google-img,
    .siri-img {
        width: 60px;
        height: 60px;
        top: -30px;
        left: -30px;
    }
}

  
  





  
  