.section1 {
    display: flex;
    justify-content: center;
    align-items: center;
    /* padding: 60px 10%; */
    background-color: #ffffff;
    position: relative;
/* figma css */
    height: 792px;
    flex-shrink: 0;
    width: 100%;

  }

  html {
    scroll-padding-top: 20px; 
  }
  .section1-content {
    display: flex;
    align-items: center;
    max-width: 1200px;
    width: 100%;
    /* margin-left: -114px; */
  }
  .mobile-h1{
    display: none;
  }
  .desktop-h1{
    display: block;
  }
  .section1-image img {
    /* width: 250px;
    max-width: 100%; */

    /* figma css */
    flex-shrink: 0;
    aspect-ratio: 379.06/627.37;
  }
  
  .section1-text {
    margin-left: 40px;
    text-align: left; 
  }
  
  .section1-text h1 {
    /* font-size: 36px;
    font-weight: bold;
    color: #000; */
    margin-bottom: 40px;

    color: #080E57;
    font-family: 'Inter', sans-serif;
    font-size: 62px;
    font-style: normal;
    font-weight: 700;
    line-height: 75px; /* 120.968% */
  }
  
  .section1-text h2 {
    /* font-size: 28px;
    font-weight: bold;
    color: #000; */

    /* figma css */
    color: #080E57;
    font-family: 'Inter', sans-serif;
    font-size: 50px;
    font-style: normal;
    font-weight: 700;
    line-height: 57px; 
    white-space: nowrap;
    margin-bottom: 40px;

  }
  
  .highlight {
    color: #011AB7;
  }
  
  .section1-text p {
    /* font-size: 16px;
    color: #555;
    line-height: 1.6;
    margin-top: 10px; */
    padding: 0;

    /* figma css */
    color: #3F404C;
    font-family: 'Inter', sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 31px; /* 129.167% */
    margin-bottom: 22px;
  }
  
  .chat-icon {
    position: absolute;
    bottom: 20px;
    right: 20px;
    background-color: #e4e8ff;
    border-radius: 50%;
    padding: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .chat-icon img {
    width: 35px;
    height: 35px;
  }
  
  .section1-text h1,
  .section1-text h2,
  .section1-text p {
    text-align: left; /* Explicitly left-aligns headings and paragraphs */
  }


/* mobile view */
.section1 {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
  position: relative;
  width: 100%;
  height: auto;
  padding: 40px 10px; /* Ensures spacing for mobile */
}

.section1-content {
  display: flex;
  align-items: center;
  max-width: 1200px;
  width: 100%;
}

/* Image Styling */
.section1-image img {
  flex-shrink: 0;
  aspect-ratio: 379.06/627.37;
}

/* Text Styling */
.section1-text {
  margin-left: 40px;
  text-align: left;
}

.section1-text h1 {
  color: #080E57;
  font-family: 'Inter', sans-serif;
  font-size: 62px;
  font-weight: 700;
  line-height: 75px;
}

.section1-text h2 {
  width: 100%;
  color: #080E57;
  font-family: 'Inter', sans-serif;
  font-size: 50px;
  font-weight: 700;
  line-height: 57px;
  white-space: wrap;
}

.highlight {
  color: #011AB7;
}

.section1-text p {
  width: 100%;
  color: #3F404C;
  font-family: 'Inter', sans-serif;
  font-size: 24px;
  font-weight: 500;
  line-height: 31px;
}
.desktop-only {
  display: block;
}

.mobile-only {
  display: none;
}

.mobile-only h2 {
  margin: 0;
  line-height: 42px; 
  font-size: 1.5rem; /* Tweak if needed */
}

.apps-title {
  margin-bottom: 1rem; /* Optional: spacing after the third line */
}
.mobile-only{
  margin-bottom: 34px;
}
/* Mobile View */
@media screen and (max-width: 768px) {
  .section1 {
    padding: 20px 15px;
    /* height: auto; */
  }

  .section1-content {
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: auto;
    text-align: center;
  }

  .section1-image img {
    /* width: 100%; */
    max-width: 350px; /* Ensures image size matches mobile view */
    height: auto;
  }

  .section1-text {
    margin-left: 0;
    text-align: center;
    padding: 20px;
  }

  .desktop-h1{
    display: none;
  }
  .mobile-h1{
    display: block;
    color: #080E57;
    font-family: 'Inter', sans-serif;
    font-size: 32px;
    line-height: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: 75px;
  }
  .section1-text h2 {
    font-size: 24px;
    line-height: 32px;
    white-space: normal;
  }

  .section1-text p {
    font-size: 16px;
    line-height: 24px;
  }
  .desktop-only {
    display: none;
  }

  .mobile-only {
    display: block;
  }
 

}
