
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.navbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 40px;
 
  width: 100%;
  height: 65px;
  flex-shrink: 0;
  border-radius: 0px 0px 20px 20px;
  background: rgba(255, 255, 255, 0.60);
  backdrop-filter: blur(25px);
  -webkit-backdrop-filter: blur(25px);
  position: fixed; 
  top: 0;
  left: 0;
  z-index: 1000; 
  transition: top 0.3s ease-in-out;
}

/* Prevent content from hiding behind the fixed navbar */
body {
  padding-top: 60px;
}

.navbar-logo {
  display: flex;
  align-items: center;
  font-size: 20px;
  font-weight: bold;
  color: #02055a;
  margin-left: 125px;
}

.navbar-logo img {
  margin-right: 10px;
  /* figma css */
  width: 33px;
  height: 33px ;
}

.navbar-logo span{
  /* figma css */
  color: #080E57;
  text-align: center;
  font-family: 'Inter', sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 37px; 
}
.navbar-links {
  list-style: none;
  display: flex;
  align-items: center;
  gap: 15px;
  margin-right: 125px;
}

.navbar-links li {
  display: inline;
  /* margin-top:5px ; */
}

.navbar-links a {
  font-family: 'Inter', sans-serif;
  text-decoration: none;
  font-size: 16px;
  transition: transform 0.2s ease-in-out, color 0.3s ease;
  color: #080E57;
  padding: 0 10px;
  display: inline-block;
  font-weight: 300;
}

.navbar-links a:hover {
  transform: scale(1.1); 
}

.contact-btn {
  font-family: 'Inter', sans-serif;
  color: white;
  border: none;
  padding: 5px 20px 5px 20px;
  border-radius: 30px;
  background: #080E57;
  font-size: 14px;
  font-weight: 400;
  cursor:pointer;
  transition: background-color 0.3s ease;
}
.contact-btn:hover {
  background-color: #00008b;
}


.contact-dropdown {
  position: absolute;
  top: 65px;
  right: 40px;
  background: white;
  border-radius: 0 0 20px 20px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  padding: 10px;
  width: 150px;
  z-index: 1001;
  position: fixed;
  margin-right: 100px;
  /* opacity: 0.7; */

  width: 152px;
  /* height: 80px; */
  flex-shrink: 0;
  border-radius: 0px 0px 20px 20px;
  border: 1px solid #DBDBDB;
  background: rgba(255, 255, 255, 0.60);
  backdrop-filter: blur(25px);
}


.contact-dropdown div {
list-style: none;
padding: 0;
margin: 0;
}

.contact-dropdown a {
display: flex;
align-items: center;
gap: 10px;
padding: 1px 10px;
text-decoration: none;

/* figma css */
color: #080E57;
text-align: center;
font-family: 'Inter', sans-serif;
font-size: 16px;
font-style: normal;
font-weight: 400;
/* line-height: 37px;  */
}
.contact-dropdown a:hover{
cursor: pointer;
}
.contact-dropdown a svg {
width: 17px;
height: 17px;
flex-shrink: 0;
}
.nav-contact-icons {
display: flex;
flex-direction: column;
gap: 10px;
padding-left: 10px;
}

.menu-toggle {
display: none;
}



/* css for mobile view */
/* Desktop Navigation */
/* .navbar-links {
list-style: none;
display: flex;
gap: 15px;
}

.navbar-links li {
display: inline;
}

.navbar-links a {
text-decoration: none;
font-size: 18px;
color: #080E57;
padding: 0 10px;
}

.contact-btn {
background: #080E57;
color: white;
padding: 5px 10px;
border-radius: 30px;
border: none;
cursor: pointer;
} */
.mobile-contact-us{
display: none !important;
}
.contact-btn-li{
display: none !important;
}

.navbar-logo-mobile{
  display: none;
}
/* Mobile Styles */
@media screen and (max-width: 768px) {
.contact-dropdown svg{
  display: none;
}
.contact-dropdown{
  margin-top: 208px;
  border-radius: 20px;
  width: 100%;
  left: 0;
  z-index: 1000;
}
.contact-dropdown a{
display: flex;   /* Ensures flex properties apply */
width: 100%;     /* Makes the anchor tag take full width */
text-align: center; /* Centers text */
justify-content: center; /* Centers text horizontally */
align-items: center; /* Centers text vertically */
/* padding: 10px;    */
text-decoration: none; /* Removes underline */
}


.nav-contact-icons{
  align-items: center;
}
.nav-contact-icons hr {
  width: 100%; 
  opacity: 1;
}
.mobile-contact-us{
  display: block !important;
  color: #080E57;
text-align: center;
font-family: 'Inter', sans-serif !important;
/* font-size: 14px !important; */
font-style: normal;
font-weight: 600 !important;
}
.menu-toggle {
  display: block;
  font-size: 24px;
  background: none;
  border: none;
  color: #080E57;
  cursor: pointer;
  margin-right: 20px;
}
.navbar{
  border-radius: 0;
  padding: 0px;
}

.navbar-links {
  position: absolute;
  top: 65px;
  right: 0;
  background: rgba(255, 255, 255, 0.60);
  -webkit-backdrop-filter: blur(25px); 
  backdrop-filter: blur(25px);
  border-radius: 0 0 20px 20px;
  width: 100%;
  left: 0;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  flex-direction: column;
  gap: 10px;
  padding: 0;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.4s ease-in-out, padding 0.4s ease-in-out;
  background-color: white;
  align-items: flex-start;
}

.navbar-links.active {
  max-height: 350px; /* Adjust based on content height */
  padding: 10px;
}

.navbar-links li {
  width: 100%;
  opacity: 1;
  transform: translateY(-10px);
  transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1); /* Light gray separator */
  padding: 10px 0;
  
}
.navbar-links a{
  /* display: flex;   */
  width: 100%;     
  text-align: left; 
  /* justify-content: center; */
  /* align-items: center; 
  text-decoration: none;  */
 }

.navbar-links.active li {
  opacity: 1;
  transform: translateY(0);
  
}
.navbar-logo {
  margin-left: 20px;
}
/* .contact-btn{
  display: none;
} */
.contact-btn-li{
  display: block !important;
}

.contact-btn {
  background: none;
  border: none;
  padding: 10px 10px;
  color: #080E57; /* Adjust to match the exact text color */
  /* font-size: 14px; */
  font-size: inherit;
  font-weight: normal;
  text-decoration: none;
  cursor: pointer;
  display: inline;
  width: 100%;
  text-align: left;
}
.contact-btn:hover{
  background: none;
}


}


@media(max-width: 768px)
{
  .navbar ul{    background: rgba(255, 255, 255, 0.60);
    backdrop-filter: blur(25px);
    -webkit-backdrop-filter: blur(25px);
  width: inherit;
  height: auto;
  }
  .navbar-items{
  width: 100%;
  }
  .navbar-links.active{
    width: 100%;
  }
  .navbar-items{
    display: block;
  }
  .navbar-logo-mobile {
    display: flex;
    align-items: center;
    font-size: 20px;
    font-weight: bold;
    color: #02055a;
    margin-left: 20px;
    gap: 6px;
  }
  .navbar-logo{
    display: none;
  }
  .navbar-items{
    display: flex;
    justify-content: space-between;
    height: inherit;
    background: rgba(255, 255, 255, 0.60);
    backdrop-filter: blur(25px);
    -webkit-backdrop-filter: blur(25px);
    width: 100%;
    
  }
  .navbar {
    backdrop-filter: none;
    -webkit-backdrop-filter: none;
    /* background: #ffffff;  */
  } 
  .navbar-logo-mobile span{
  font-family: 'Inter', sans-serif;
  }
}


