@media screen and (max-width: 768px) {
    .contact-container {
      text-align: center;
    }
  
    .contact-toggle {
      font-size: 18px;
      font-weight: bold;
      background: none;
      border: none;
      color: #080E57;
      cursor: pointer;
    }
  
    .contact-menu {
      background: rgba(255, 255, 255, 0.9);
      backdrop-filter: blur(25px);
      border-radius: 10px;
      padding: 10px 20px;
      width: 90%;
      max-width: 300px;
      margin: 10px auto;
      box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    }
  
    .contact-menu ul {
      list-style: none;
      padding: 0;
      margin: 0;
    }
  
    .contact-menu li {
      padding: 12px 0;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      font-size: 16px;
      color: #080E57;
    }
  
    .contact-menu li:last-child {
      border-bottom: none;
    }
  }
  