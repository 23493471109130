.section2 {
    text-align: center;
    padding: 80px 10%;
    height: 990px;
    background: 
    /* From top to 50px */
        linear-gradient(to bottom, #FFFFFF 0px, #DDDFFF 61px), 
        /*  From bottom to 50px */
        /* linear-gradient(to top, #FFFFFF 0px, #C3C7FF 50px),  */
        #DDDFFF;
        /* linear-gradient(to bottom, #DDDFFF, #ffffff), Gradient */
        /* url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="1440" height="449" viewBox="0 0 1440 449" fill="none"><path d="M384.774 80.6604C203.725 -10.3897 63.1249 -7.386 -0.973793 9.20449L1.77852 444.346L695.5 444.346L1445.99 448.852L1450.07 64.5348C1342.87 135.143 1068.48 312.121 869.714 308.066C621.258 302.996 611.084 194.473 384.774 80.6604Z" fill="url(%23paint0_linear_466_363)"/><defs><linearGradient id="paint0_linear_466_363" x1="604.429" y1="-78.644" x2="632.622" y2="432.3" gradientUnits="userSpaceOnUse"><stop offset="0.000999756" stop-color="%23FBFCFF"/><stop offset="1" stop-color="%23B8BEFF"/></linearGradient></defs></svg>');  */
        
    background-repeat: no-repeat;
    background-size: cover; /* Adjust this as needed */
    background-position: center;
    /* width: 1441px; */
    height: 843px;
  }
 

  .section2 {
    width: 100%;
    max-width: 100vw;
    overflow: hidden;
    position: relative;
}
    /* Background SVG */
  .svg-background1 {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%); /* Centers the SVG horizontally */
    width: 100%; /* Ensures actual SVG width */
    /* height: 625px;  */
    overflow: hidden;
}
.svg-background1 {
  width: 100vw; /* Ensures full viewport width */
  left: 0; /* Align to the left */
  transform: none; /* Remove translateX to prevent centering issues */
}
.svg-background1 svg{
  /* border: 5px solid teal; */
  margin-bottom: -10px;
}

.background-svg1 {
    width: 100%;
    height: auto;
    display: block;
}
  .section2 h2{
    font-family: 'Inter', sans-serif;
  }
  
  .section2-title {
    color: #080E57;
    font-family: 'Inter', sans-serif;
    font-size: 62px;
    font-style: normal;
    font-weight: 700;
    line-height: 57px; /* 91.935% */
  }
  
  .section2-content {
    display: flex;
    justify-content: center;
    /* justify-content: space-between; */
    justify-content: space-around;
    align-items: center;
    max-width: 1200px;
    margin: auto;
    /* margin-top: -20px; */
    gap: 30px;
    /* height: 400px; */

  }
  .section2-text {
    text-align: left;
    flex: 1;
    max-width: 50%;
    margin-top: -110px;
  }
  
  .section2-text p {
    /* font-size: 18px;
    color: #333;
    margin: 10px 0; */

    /* figma css */
    color: #3F404C;
    text-align: left;
    font-family: 'Inter', sans-serif;
    font-size: 34px;
    font-style: normal;
    font-weight: 600;
    line-height: 35px; /* 102.941% */
    padding: 0;
    margin-bottom: 37px;
  }
  
  .future-text {
    /* font-size: 30px;
    font-weight: bold;
    color: #0b1446;
    margin-top: 20px; */

    margin-top: 9px;
    
    /* figma css */
    color: #080E57;
    font-family: "Inria Serif";
    font-size: 70px;
    font-style: normal;
    font-weight: 700;
    line-height: 73px; /* 104.286% */
  }

  /* .section2-image {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: -35px;
    position: relative;
    width: 250px;  
    height: 570px; 
  
    border-radius: 20px; 
    overflow: hidden;
  } */
  .section2-image {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative; /* Required for absolute positioning of video */
    width: 250px;  /* Keep the image size */
    height: 570px; /* Ensures consistent height */
    border-radius: 20px;
    overflow: hidden; /* Prevents video overflow */
  }

  .section2-image img,
  .section2-image video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover; 
    border-radius: 20px;
    /* opacity: 0;
    transition: opacity 0.3s ease-in-out; */
  }
  
  .section2-image:hover video {
    opacity: 1;
  }
  .section2-content{
    position: relative;
  }

  .underline {
    text-decoration: underline;
  }
.section2-image{
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.25);
}

/* Mobile view */


/* Mobile View */
@media screen and (max-width: 768px) {
  .section2 {
    height: 1010px;
    padding: 40px 5%;
  }
  .section2-title{
    font-size: 34px;
  }

  .section2-content {
    flex-direction: column-reverse;
    text-align: center;
    /* gap: 115px; */
  }

  .section2-text {
    max-width: 100%;
    margin-top: 32px;
  }

  .section2-text p {
    text-align: left;
    line-height: 35px;
    color: #3F404C;
    font-family: 'Inter', sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 35px; 
    margin-bottom: 0px;
    padding: 0;
  }

  .future-text {
    font-size: 34px;
    margin-top: 0;
  }

  .section2-image {
    /* width: 100%; */
    display: flex;
    justify-content: center;
  }

  .section2-image img,
  .section2-image video {
    width: 100%;
    max-width: 300px;
    /* height: auto; */
    height: 100%;
  }

  /* changes for video play */
  /* .section2-content {
    flex-direction: column-reverse;
    gap: 50px; 
  }

  .section2-image {
    width: 100%;
    max-width: 300px; 
    height: auto;
  } */
}
