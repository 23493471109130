.feature-card {
    width: 245.752px;
    height: 245px;
    flex-shrink: 0;
    border-radius: 15px;
    /* background: linear-gradient(to bottom, #FFF  30%, #C3C7FF  30%); */
    /* background: linear-gradient(0deg, #FFF 0%, #C3C7FF 100%); */
    /* background: linear-gradient(to bottom, #FFF 30%, #C3C7FF 100%); */
    background: linear-gradient(to bottom, #FFF 30%, #C3C7FF 30%, #FFF 100%);

    box-shadow: 2px 4px 10px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding: 20px;
    text-align: center;
    position: relative;
    overflow: hidden;
  }
  
  .feature-icon {
    width: 60px;
    height: 60px;
    background-color: #09166f;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 43px;
    left: 50%;
    transform: translateX(-50%);
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  }
  
  h3 {
    margin-top: 90px; 
    color: #19232B;
    text-align: center;
    font-family: 'Inter', sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  
  p {
    color: #19232B;
    text-align: center;
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    padding: 12px;
  }
  @media (max-width: 768px) {
    .feature-card{
      width: 170px;
      height: 167.486px;
      padding: 3.66px;
      box-shadow: 0px 0px 15px 0px rgba(8, 14, 87, 0.32);
    }
    .feature-card h3{
      font-size: 14px;
    }
    /* .varient-2 svg{
      margin-bottom: 100px;
    } */
    .feature-card.varient-2 h3{
      font-size: 14px;
      margin: 76px 0 3px;
    
    }
    
    .feature-card p{
      font-size: 10px;
      padding: 0;
    }
    .feature-icon{
      width: 50.428px;
      height: 49.835px;
      top: 24.15px;
      left: 50%;

    }
  }