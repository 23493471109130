.louie-in-media {
    text-align: center;
    padding: 80px 10%;
    background: 
      linear-gradient(to bottom, #DDDFFF 0px, #FFFFFF 92px),
      #ffffff;
  }
  
  h2 {
    /* color: #080e57;
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 40px; */

    /* figma css */
    color: #080E57;
    font-family: Inter;
    font-size: 50px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-bottom: 61px;
  }
  
  /* Article Container */
  .articles {
    display: flex;
    justify-content: center;
    gap: 30px;
    flex-wrap: wrap;
  }
  
  /* Card */
  .article-card {
    width: 300px;
    background: white;
    border-radius: 12px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    transition: transform 0.3s ease;
  }
  
  .article-card:hover {
    transform: translateY(-5px);
  }
  
  /* Content */
  .article-content {
    padding: 15px;
    text-align: left;
  }
  
  .article-content h3 {
    font-size: 16px;
    font-weight: bold;
    color: #080e57;
  }
  
  .article-content .date {
    font-size: 14px;
    color: #666;
    margin: 5px 0;
  }
  
  .article-content p {
    font-size: 14px;
    color: #333;
    line-height: 1.5;
  }
  /* figma css */
  .date{
    color: #888;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  .content{
    color: #545454;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }