.contact-container {
    position: fixed;
    right: 0;
    top: 33%;
    transform: translateY(-50%);
    background-color: #080E57;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
    width: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    /* transition: width 0.3s ease-in-out; */
    /* transition: width 0.3s ease-out, opacity 0.3s ease-out, transform 0.3s ease-out; */
    cursor: pointer;
    z-index: 2;
    width: 44px;
    height: 125px;
}

.contact-tab {
    color: white;
    font-size: 16px;
    font-weight: bold;
    /* writing-mode: sideways-lr; */
    writing-mode: vertical-rl;
    transform: rotate(180deg);
    text-orientation: mixed;
    padding: 5px;
    text-align: center;

    color: #FFF;
    font-family: 'Inter', sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}
.contact-tab1{
    color: #080E57;
    font-family: 'Inter', sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}
.contact-expanded {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #c8cdff;
    padding: 8px;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
    margin-right: 50px;

    height: 125px;

}
  
.contact-icons {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding-left: 10px;
}
  