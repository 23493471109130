.section4 {
  /* position: relative; */
  text-align: center;
  padding: 50px;
  height: 1282px;
  background-color:
  #DDDFFF;
  /* linear-gradient(to bottom, #DDDFFF 0px, #FFFFFF 132px),  */
  background: 
  linear-gradient(to bottom, #FFFFFF 0px, #DDDFFF 61px), 
  #DDDFFF;
}



.section4 h2 {
  color: #080E57;
  text-align: center;
  font-family: 'Inter', sans-serif;
  font-size: 50px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.section4 {
  position: relative; /* Ensure it is the positioning context */
  text-align: center;
  padding: 108px 0% 50px; 
  height: 1282px;
  background: 
    linear-gradient(to bottom, #FFFFFF 0px, #DDDFFF 160px),
}

.grid {
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr)); /* Ensures equal columns */
  gap: 95.1px;
  width: 100%;  /* Ensures full width */
  max-width: calc(3 * 250px + 2 * 95.1px); /* Adjust based on card width */
  margin: 0 auto; /* Centers the grid */
  /* justify-content: space-evenly; */
}


.card:hover {
  transform: translateY(-5px);
}

.icon {
  width: 60px;
  height: 60px;
  margin: 0 auto 10px;
  background: linear-gradient(to right, #7b82ff, #b8beff);
  color: white;
  font-size: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}

.card h3 {
  color: #080e57;
  font-size: 16px;
  font-weight: bold;
  margin: 10px 0;
}

.card p {
  color: #555;
  font-size: 14px;
}

/* Mobile view css */
@media (max-width: 768px) {
  .section4 {
    padding: 50px;
    height: auto; /* Allow height to adjust based on content */
    background: linear-gradient(to bottom, #FFFFFF 0px, #DDDFFF 160px);
  }

  .section4 h2 {
    font-size: 34px; /* Adjust font size for better readability */
  }

  .grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 23.1px;  /* previously it was 33.1px*/
    justify-content: center;
  }

  /* .grid {
    grid-template-columns: repeat(2, minmax(0, 1fr));
    max-width: calc(2 * 250px + 1 * 33.1px); 
  } */
  .card {
    padding: 15px; /* Adjust padding */
    text-align: center;
  }

  .icon {
    width: 50px;
    height: 50px;
    font-size: 24px;
  }

  .card h3 {
    font-size: 14px;
  }

  .card p {
    font-size: 12px;
  }
}

/* If the screen is very small (e.g., below 480px), fall back to one card per row */
/* @media (max-width: 480px) {
  .grid {
    grid-template-columns: 1fr; 
  }
} */

