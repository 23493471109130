.section5 {
    text-align: center;
    padding: 80px 10%;
    /* background: linear-gradient(to bottom, #e8ebff, #ffffff 50%, #e8ebff); */
    background: 
    linear-gradient(to bottom, #DDDFFF 0px, #FFFFFF 61px), 
      #FFFFFF
  }
  
.title{
    color: #080E57;
    text-align: center;
    font-family: 'Inter', sans-serif;
    font-size: 50px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-bottom: 85px;
}
  
  /* Layout */
  .content {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 109px;
  }
  
  /* Left - Diagram */
  .diagram img {
    /* figma css */
    width: 545px;
    height: 460px;
    flex-shrink: 0;
    border-radius: 20px;
    /* box-shadow: 0px 0px 15px 0px rgba(8, 14, 87, 0.32); */
  }
  
  /* Right - Info */
  .info ul {
    list-style: none;
    text-align: left;
    margin: -25px 0 15px 0;
    padding: 0;
  }
  
  .info li {
    color: #3E3F4B;
    font-family: 'Inter', sans-serif;
    font-size: 22px;
    font-style: normal;
    font-weight: 500;
    line-height: 118%; 
    margin-top: 35px;
    display: flex;
    align-items: center;
  }
  
  .info li::before {
    /* content: "✔"; */
    color: #4a69bd;
    font-weight: bold;
    margin-right: 10px;
  }

  .info svg{
    /* figma css */
    width: 32px;
    height: 32px;
    flex-shrink: 0;
    aspect-ratio: 1/1;
    margin-right: 22px;
  }

  /* Mobile View CSS */
  @media (max-width: 768px) {
    .section5 p{
      font-size: 34px;
    }
    .content{
      flex-direction: column;
      gap: 54px;
    }
    .diagram{
      margin: 0 20px 0 20px;
    }
    .diagram img{
      width: 372px;
      height: 313px;

    }
    .info li{
      font-size: 14px;
    }
    .info svg{
      width: 15px;
      height: 15px;
      flex-shrink: 0;
      aspect-ratio: 1/1;
    }
    .title{
      margin-bottom: 38px;
    }
  }