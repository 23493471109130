.innerPages {
    padding: 8em 0em 3em 0em;
    margin: -80px 140px 0 140px;
  }
  
  .innerPages h2 {
    font-size: 3em;
    color: #060e55;
    margin: 0 0 0 0;
  }
  
  .innerPages h2 span {
    color: #999;
    font-size: 14px;
  }
  
  .innerPages p {
    line-height: 30px;
    text-align: justify;
  }
  
  .innerPages h3 {
    margin-top: 1.5em;
    margin-bottom: 0.5em;
  }
  
  .innerPages ul {
    margin: 0px;
    padding: 0px;
    margin-bottom: 1.5em;
  }
  
  .innerPages ul li {
    list-style: url(/public/luLi.png);
    text-align: left;
    margin-bottom: 8px;
    margin-left: 30px;
    font-size: 23px;
    font-style: 'Inter', sans-serif;
  }
  
  .innerPages a {
    color: #05417d;
  }
  
  .innerPages a:hover {
    color: #0d1dd2;
  }
  
.col-md-12 h3{
    text-align: left;
    font-size: 28px;
    color: #212529;
    /* font-style: 'Libre Baskerville',serif; */
    /* font-style: "'Libre Baskerville', serif"; */
    font-style: 'Inter', sans-serif;
}